<!-- view-sign -->
<template>
  <div class="view-sign">
    <!-- 签约信息 -->
    <div class="sign-info">
      <!-- 标题栏 -->
      <div class="info-title">
        <custom-subtitle title="签约详情" />
      </div>

      <!-- 内容 -->
      <div class="info-content" v-loading="loading">
        <div class="info-item">
          <div class="item-label">签约时间:</div>
          <div class="item-value">{{ signObj.createTime }}</div>
        </div>

        <div class="info-item">
          <div class="item-label">游戏名称:</div>
          <div class="item-value">{{ signObj.gameName }}</div>
        </div>

        <div class="info-item">
          <div class="item-label">签约天数:</div>
          <div class="item-value">{{ signObj.days }}</div>
        </div>

        <div class="info-item">
          <div class="item-label">支付方式:</div>
          <div class="item-value">
            {{ signObj.payment | paymentTypeText }}
          </div>
        </div>

        <div class="info-item">
          <div class="item-label">价位:</div>
          <div class="item-value">
            {{ signObj | priceText }}
          </div>
        </div>

        <div class="info-item">
          <div class="item-label">预算(元):</div>
          <div class="item-value">
            {{ signObj.budget }}
          </div>
        </div>

        <div class="info-item">
          <div class="item-label">是否挂码:</div>
          <div class="item-value">
            {{ signObj.qrcode | qrcodeTypeText }}
          </div>
        </div>
      </div>
    </div>

    <!-- 签约备注 -->
    <div class="sign-remark">
      <!-- 标题栏 -->
      <div class="remark-title">
        <custom-subtitle title="签约备注" />
      </div>

      <!-- 内容 -->
      <div class="remark-content" v-loading="loadingRemarks">
        <!-- 签约备注空占位符 -->
        <empty-placeholder
          v-if="!cooperationSignRemarks || !cooperationSignRemarks.length"
          placeholder="暂无签约备注"
        />

        <el-timeline v-else class="remark-content-timeline">
          <el-timeline-item
            v-for="remark in cooperationSignRemarks"
            type="info"
            icon="el-icon-info"
            :timestamp="remark.createTime"
            :key="remark._id"
          >
            <div class="timeline-item-content">{{ remark.content }}</div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import CustomSubtitle from '../../../../../components/custom-subtitle';
  import EmptyPlaceholder from '../../../../../components/empty-placeholder';
  import paymentType from '../../../../../enum/payment-type';
  import qrcodeType from '../../../../../enum/qrcode-type.js';

  export default {
    name: 'view-sign',
    components: {
      CustomSubtitle,
      EmptyPlaceholder,
    },
    filters: {
      // 支付方式文案
      paymentTypeText(val) {
        return paymentType.map[val] || '';
      },
      // 价位文案
      priceText(val) {
        const { payment, priceCPT, priceCPS } = val;
        let paymentPriceText = '';

        if (!payment) return paymentPriceText;

        switch (payment) {
          case paymentType.enum.cps:
            paymentPriceText = `${priceCPS}%`;
            break;

          case paymentType.enum.cptAndCps:
            paymentPriceText = `${priceCPT}元/天 + ${priceCPS}%`;
            break;

          default:
            paymentPriceText = `${priceCPT}元/天`;
        }

        return paymentPriceText;
      },
      // 是否挂码文案
      qrcodeTypeText(val) {
        return qrcodeType.map[val] || '';
      },
    },
    data() {
      return {
        loading: false,
        loadingRemarks: false,
      };
    },
    computed: {
      ...mapState('anchor/cooperation/view', [
        'cooperationSign',
        'cooperationSignRemarks',
      ]),
      ...mapGetters('anchor/cooperation/view', ['signId']),

      // 签约对象
      signObj() {
        return this.cooperationSign || {};
      },
    },
    methods: {
      ...mapActions('anchor/cooperation/view', [
        'actCooperationSign',
        'actCooperationSignRemarks',
      ]),

      // 获取合作关联的签约对象
      async getCooperationSign() {
        this.loading = true;

        await this.actCooperationSign();

        this.loading = false;
      },
      // 获取签约关联的签约备注列表
      async getSignRemarks() {
        this.loadingRemarks = true;

        await this.actCooperationSignRemarks();

        this.loadingRemarks = false;
      },
    },
    watch: {
      signId: {
        immediate: true,
        handler(n) {
          if (!n) return;

          this.getCooperationSign();
          this.getSignRemarks();
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  .view-sign {
    font-size: 13px;

    // 签约信息
    .sign-info {
      // 内容
      .info-content {
        padding-left: 14px;
        font-size: 13px;
        color: #666;

        .info-item {
          display: inline-flex;
          width: 25%;
          margin-top: 10px;

          .item-label {
            width: 60px;
            color: #909399;
          }

          .item-value {
            margin-left: 4px;
          }
        }
      }
    }

    // 签约备注
    .sign-remark {
      margin-top: 20px;

      .remark-content {
        margin-top: 15px;
        padding-left: 16px;

        .remark-content-timeline .timeline-item-content {
          font-size: 13px;
          color: #666;
        }
      }
    }
  }
</style>
