<!-- view-serial -->
<template>
  <div class="view-serial">
    <!-- 主播流水信息 -->
    <div class="serial-info">
      <!-- 标题栏 -->
      <div class="info-title">
        <custom-subtitle title="流水详情" />
      </div>

      <!-- 内容 -->
      <div class="info-content">
        <el-table v-loading="loading" :data="cooperationSerials" size="small">
          <el-table-column label="流水日期" prop="serialDate" />

          <el-table-column label="游戏名称" prop="gameName" />

          <el-table-column label="流水数" prop="income" />

          <el-table-column label="注册数" prop="registration" />

          <el-table-column label="支出数">
            <template v-slot="{ row: { expenditureCPT, expenditureCPS } }">
              {{ expenditureCPT + expenditureCPS }}
            </template>
          </el-table-column>

          <el-table-column label="流水类型">
            <template v-slot="{ row: { serialType } }">
              <el-tag :type="serialType | serialTypeTagType" size="mini">
                {{ serialType | serialTypeText }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column label="创建时间" prop="createTime" width="180px" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import CustomSubtitle from '../../../../../components/custom-subtitle';
  import serialType from '../../../../../enum/serial-type';

  export default {
    name: 'view-serial',
    components: {
      CustomSubtitle,
    },
    filters: {
      // 流水类型标签类型
      serialTypeTagType(val) {
        const classMap = {
          [serialType.enum.cooperative]: 'primary',
          [serialType.enum.subsequent]: 'success',
        };

        return classMap[val];
      },
      // 流水类型文案
      serialTypeText(val) {
        return serialType.map[val] || '';
      },
    },
    data() {
      return {
        loading: false,
      };
    },
    computed: {
      ...mapState('anchor/cooperation/view', ['cooperationSerials']),
      ...mapGetters('anchor/cooperation/view', ['signId']),
    },
    methods: {
      ...mapActions('anchor/cooperation/view', ['actCooperationSerials']),

      // 获取合作关联的主播流水列表
      async getCooperationSerials() {
        this.loading = true;

        await this.actCooperationSerials();

        this.loading = false;
      },
    },
    watch: {
      signId: {
        immediate: true,
        handler(n) {
          if (!n) return;

          this.getCooperationSerials();
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  .view-serial {
    font-size: 13px;

    // 主播流水信息
    .serial-info {
      // 内容
      .info-content {
        padding-left: 14px;
        margin-top: 10px;
        font-size: 13px;
        color: #666;

        .info-item {
          display: inline-flex;
          width: 25%;
          margin-top: 10px;

          .item-label {
            width: 60px;
            color: #909399;
          }

          .item-value {
            margin-left: 4px;
          }
        }
      }
    }
  }
</style>
