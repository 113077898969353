<!-- view-schedule -->
<template>
  <div class="view-schedule" v-loading="loading">
    <!-- 排期信息 -->
    <div class="schedule-info">
      <!-- 标题栏 -->
      <div class="info-title">
        <custom-subtitle title="排期详情" />
      </div>

      <!-- 内容 -->
      <div class="info-content">
        <div class="info-item">
          <div class="item-label">首播时间:</div>
          <div class="item-value">
            {{ scheduleObj.firstShowTime }}
          </div>
        </div>

        <div class="info-item">
          <div class="item-label">合作方式:</div>
          <div class="item-value">
            {{ scheduleObj.cooperationType | cooperationTypeText }}
          </div>
        </div>

        <div class="info-item">
          <div class="item-label">推广链接名:</div>
          <div class="item-value">{{ scheduleObj.promoteLink || '-' }}</div>
        </div>

        <div class="info-item">
          <div class="item-label">QQ群:</div>
          <div class="item-value">{{ scheduleObj.contactNo || '-' }}</div>
        </div>

        <div class="info-item">
          <div class="item-label">带玩运营:</div>
          <div class="item-value">
            {{ scheduleObj.playOperatorName || '-' }}
          </div>
        </div>

        <div class="info-item">
          <div class="item-label">跟进运营:</div>
          <div class="item-value">{{ scheduleObj.operatorName || '-' }}</div>
        </div>

        <div class="info-item">
          <div class="item-label">排期时间:</div>
          <div class="item-value">{{ scheduleObj.createTime }}</div>
        </div>

        <div class="info-item">
          <div class="item-label">投放时间:</div>
          <div class="item-value">{{ scheduleObj.publishTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import CustomSubtitle from '../../../../../components/custom-subtitle';
  import paymentType from '../../../../../enum/payment-type.js';
  import qrcodeType from '../../../../../enum/qrcode-type.js';
  import cooperationType from '../../../../../enum/cooperation-type.js';

  export default {
    name: 'view-schedule',
    components: {
      CustomSubtitle,
    },
    filters: {
      // 支付方式文案
      paymentTypeText(val) {
        return paymentType.map[val] || '';
      },
      // 价位文案
      priceText(val) {
        const { payment, priceCPT, priceCPS } = val;
        let paymentPriceText = '';

        switch (payment) {
          case paymentType.enum.cps:
            paymentPriceText = `${priceCPS}%`;
            break;

          case paymentType.enum.cptAndCps:
            paymentPriceText = `${priceCPT}元/天 + ${priceCPS}%`;
            break;

          default:
            paymentPriceText = `${priceCPT}元/天`;
        }

        return paymentPriceText;
      },
      // 是否挂码文案
      qrcodeTypeText(val) {
        return qrcodeType.map[val] || '';
      },
      // 排期类型文案
      cooperationTypeText(val) {
        return cooperationType.map[val] || '';
      },
    },
    data() {
      return {
        loading: false,
      };
    },
    computed: {
      ...mapState('anchor/cooperation/view', ['cooperationSchedule']),
      ...mapGetters('anchor/cooperation/view', ['signId']),

      // 排期对象
      scheduleObj() {
        return this.cooperationSchedule || {};
      },
    },
    methods: {
      ...mapActions('anchor/cooperation/view', ['actCooperationSchedule']),

      // 获取合作关联的排期对象
      async getCooperationSchedule() {
        this.loading = true;

        await this.actCooperationSchedule();

        this.loading = false;
      },
    },
    watch: {
      signId: {
        immediate: true,
        handler(n) {
          if (!n) return;

          this.getCooperationSchedule();
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  .view-schedule {
    font-size: 13px;

    // 排期信息
    .schedule-info {
      // 内容
      .info-content {
        padding-left: 14px;
        font-size: 13px;
        color: #666;

        .info-item {
          display: inline-flex;
          width: 25%;
          margin-top: 10px;

          .item-label {
            width: 70px;
            color: #909399;
          }

          .item-value {
            margin-left: 4px;
          }
        }
      }
    }

    // 签约备注
    .schedule-sign {
      margin-top: 20px;

      .sign-content {
        margin-top: 15px;
        padding-left: 16px;
      }
    }
  }
</style>
