// 是否挂二维码
export default {
  map: {
    1: '是',
    2: '否',
  },
  enum: {
    yes: 1,
    no: 2,
  },
};
