// 开播状态
export default {
  map: {
    1: '未开播',
    2: '已开播',
  },
  enum: {
    unBroadcasted: 1,
    broadcasted: 2,
  },
};
