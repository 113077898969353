<!-- cooperation-view -->
<template>
  <div class="cooperation-view">
    <!-- 主播信息 -->
    <div class="view-anchor">
      <!-- 标题栏 -->
      <div class="anchor-title">
        <custom-title title="主播信息" />

        <el-button
          size="small"
          icon="el-icon-back"
          @click="handleTitleBackClick"
          >返回</el-button
        >
      </div>

      <!-- 内容 -->
      <div class="anchor-content" v-loading="loading">
        <!-- 主播详情 -->
        <div class="anchor-info">
          <div class="info-item">
            <div class="item-label">主播名称:</div>
            <div class="item-value">
              {{ cooperationObj.anchorName }}
            </div>
          </div>

          <div class="info-item">
            <div class="item-label">直播平台:</div>
            <div class="item-value">
              {{ cooperationObj.platformName }}
            </div>
          </div>

          <div class="info-item">
            <div class="item-label">游戏版块:</div>
            <div class="item-value">
              {{ cooperationObj.categoryName }}
            </div>
          </div>

          <div class="info-item">
            <div class="item-label">直播间号:</div>
            <div class="item-value">
              {{ cooperationObj.roomNo }}
            </div>
          </div>

          <div class="info-item">
            <div class="item-label">粉丝牌:</div>
            <div class="item-value">
              {{ cooperationObj.fansName }}
            </div>
          </div>

          <div class="info-item">
            <div class="item-label">贵宾数:</div>
            <div class="item-value">
              {{ cooperationObj.vipName }}
            </div>
          </div>

          <div class="info-item">
            <div class="item-label">热度:</div>
            <div class="item-value">
              {{ cooperationObj.heatName }}
            </div>
          </div>

          <div class="info-item">
            <div class="item-label">订阅量:</div>
            <div class="item-value">
              {{ cooperationObj.subscriptionName }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作阶段 -->
    <div class="view-stage">
      <!-- 标题栏 -->
      <div class="stage-title">
        <custom-title title="合作阶段" />
      </div>

      <!-- 内容 -->
      <div class="stage-content">
        <!-- 阶段 tab -->
        <div class="stage-tab">
          <tab v-model="activeStageValue" :tabs="cooperationStages" />
        </div>

        <!-- 阶段详情 -->
        <div class="stage-info">
          <component :is="stageCom" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import CustomTitle from '../../../../components/custom-title';
  import Tab from '../../../../components/tab';
  import ViewSign from './view-sign';
  import ViewSchedule from './view-schedule';
  import ViewBroadcast from './view-broadcast';
  import ViewSerial from './view-serial';

  // 合作阶段列表
  const cooperationStages = [
    {
      label: '签约主播',
      value: 'anchor-sign',
      component: ViewSign,
    },
    {
      label: '主播排期',
      value: 'anchor-schedule',
      component: ViewSchedule,
    },
    {
      label: '开播记录',
      value: 'anchor-broadcast',
      component: ViewBroadcast,
    },
    {
      label: '主播流水',
      value: 'anchor-serial',
      component: ViewSerial,
    },
  ];

  export default {
    name: 'cooperation-view',
    components: {
      CustomTitle,
      Tab,
    },
    props: {
      // 合作记录 id
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        // 合作阶段列表
        cooperationStages,
        // 当前选中的阶段名，默认第一个
        activeStageValue: cooperationStages[0].value,
        loading: false,
      };
    },
    computed: {
      ...mapState('anchor/cooperation/view', ['cooperation']),

      // 合作阶段组件
      stageCom() {
        const { component } = this.cooperationStages.find(
          item => item.value === this.activeStageValue
        );

        return component;
      },
      // 合作对象
      cooperationObj() {
        return this.cooperation || {};
      },
    },
    created() {
      this.mttCooperationId(this.id);
    },
    mounted() {
      this.getCooperation();
    },
    methods: {
      ...mapMutations('anchor/cooperation/view', ['mttCooperationId']),
      ...mapActions('anchor/cooperation/view', ['actCooperation']),

      // 获取合作对象
      async getCooperation() {
        this.loading = true;

        await this.actCooperation();

        this.loading = false;
      },
      // 标题栏返回按钮单击
      handleTitleBackClick() {
        this.$router.go(-1);
      },
    },
  };
</script>

<style scoped lang="scss">
  .cooperation-view {
    // 主播信息
    .view-anchor {
      // 标题栏
      .anchor-title {
        display: flex;
        justify-content: space-between;
      }

      // 内容
      .anchor-content {
        margin-left: 1px;

        // 主播详情
        .anchor-info {
          font-size: 13px;
          color: #666;

          .info-item {
            display: inline-flex;
            width: 25%;
            margin-top: 10px;

            .item-label {
              width: 60px;
              color: #909399;
            }

            .item-value {
              margin-left: 4px;
            }
          }
        }
      }
    }

    // 阶段信息
    .view-stage {
      margin-top: 40px;

      // 内容
      .stage-content {
        margin-top: 10px;

        // 阶段详情
        .stage-info {
          margin-top: 15px;
        }
      }
    }
  }
</style>
