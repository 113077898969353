<!-- view-broadcast -->
<template>
  <div class="view-broadcast">
    <!-- 开播记录信息 -->
    <div class="broadcast-info">
      <!-- 标题栏 -->
      <div class="info-title">
        <custom-subtitle title="开播记录详情" />
      </div>

      <!-- 内容 -->
      <div class="info-content">
        <el-table
          v-loading="loading"
          :data="cooperationBroadcasts"
          size="small"
        >
          <el-table-column label="开播日期" prop="broadcastDate" />

          <el-table-column label="主播名称" prop="anchorName" />

          <el-table-column label="直播平台" prop="platformName" />

          <el-table-column label="游戏名称" prop="gameName" />

          <el-table-column label="开播状态">
            <template v-slot="{ row: { status } }">
              <el-tag :type="status | broadcastStatusTagType" size="mini">
                {{ status | broadcastStatusText }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column label="更新时间" prop="updateTime" width="180px" />
        </el-table>
      </div>
    </div>

    <!-- 开播记录备注 -->
    <div class="broadcast-remark">
      <!-- 标题栏 -->
      <div class="remark-title">
        <custom-subtitle title="开播记录备注" />
      </div>

      <!-- 内容 -->
      <div class="remark-content" v-loading="loadingRemarks">
        <!-- 开播记录备注空占位符 -->
        <empty-placeholder
          v-if="
            !cooperationBroadcastsRemarks ||
            !cooperationBroadcastsRemarks.length
          "
          placeholder="暂无开播记录备注"
        />

        <el-timeline v-else class="remark-content-timeline">
          <el-timeline-item
            v-for="remark in cooperationBroadcastsRemarks"
            type="info"
            icon="el-icon-info"
            :timestamp="remark.createTime"
            :key="remark._id"
          >
            <div class="timeline-item-content">
              <span class="content-date">{{
                `【${remark.broadcastDate}】`
              }}</span>
              <span>{{ remark.content }}</span>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import CustomSubtitle from '../../../../../components/custom-subtitle';
  import EmptyPlaceholder from '../../../../../components/empty-placeholder';
  import broadcastStatus from '../../../../../enum/broadcast-status.js';

  export default {
    name: 'view-broadcast',
    components: {
      CustomSubtitle,
      EmptyPlaceholder,
    },
    filters: {
      // 开播记录状态标签类型
      broadcastStatusTagType(val) {
        const classMap = {
          [broadcastStatus.enum.unBroadcasted]: 'danger',
          [broadcastStatus.enum.broadcasted]: 'success',
        };

        return classMap[val];
      },
      // 开播记录状态文案
      broadcastStatusText(val) {
        return broadcastStatus.map[val] || '';
      },
    },
    data() {
      return {
        loading: false,
        loadingRemarks: false,
      };
    },
    computed: {
      ...mapState('anchor/cooperation/view', [
        'cooperationBroadcasts',
        'cooperationBroadcastsRemarks',
      ]),
      ...mapGetters('anchor/cooperation/view', ['signId', 'broadcastIds']),
    },
    methods: {
      ...mapActions('anchor/cooperation/view', [
        'actCooperationBroadcasts',
        'actCooperationBroadcastsRemarks',
      ]),

      // 获取合作关联的开播记录列表
      async getCooperationBroadcasts() {
        this.loading = true;

        await this.actCooperationBroadcasts();

        this.loading = false;
      },
      // 获取合作关联的开播记录备注列表
      async getCooperationBroadcastsRemarks() {
        this.loadingRemarks = true;

        await this.actCooperationBroadcastsRemarks();

        this.loadingRemarks = false;
      },
    },
    watch: {
      signId: {
        immediate: true,
        handler(n) {
          if (!n) return;

          this.getCooperationBroadcasts();
        },
      },
      broadcastIds(n) {
        if (!n) return;

        this.getCooperationBroadcastsRemarks();
      },
    },
  };
</script>

<style scoped lang="scss">
  .view-broadcast {
    font-size: 13px;

    // 开播记录信息
    .broadcast-info {
      // 内容
      .info-content {
        padding-left: 14px;
        margin-top: 10px;
        font-size: 13px;
        color: #666;

        .info-item {
          display: inline-flex;
          width: 25%;
          margin-top: 10px;

          .item-label {
            width: 60px;
            color: #909399;
          }

          .item-value {
            margin-left: 4px;
          }
        }
      }
    }

    // 开播记录备注
    .broadcast-remark {
      margin-top: 20px;

      .remark-content {
        margin-top: 15px;
        padding-left: 16px;

        .remark-content-timeline .timeline-item-content {
          font-size: 13px;
          color: #666;

          .content-date {
            margin: 0 0 0 -7px;
          }
        }
      }
    }
  }
</style>
