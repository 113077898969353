// 流水类型
export default {
  map: {
    1: '合作中',
    2: '后续',
  },
  enum: {
    cooperative: 1,
    subsequent: 2,
  },
};
